import Vue from 'vue';

const EventBus = new Vue();

EventBus.listenerReady = false;

EventBus.myReportsListenerReady = false;
EventBus.myReportsWithIDListenerReady = false;

EventBus.campaignsReady = false;
EventBus.mediaReady = false;
EventBus.creativesReady = false;
EventBus.audienceReady = false;

EventBus.performanceAPILoadedOnAIEnabled = false;

EventBus.universalPixelIdReady = false;

// By default, fetchSavedReports
EventBus.fetchSavedReports = true;

EventBus.universalPixelIdReady = false;

EventBus.isCampaignIdsReady = false;

export default EventBus;
